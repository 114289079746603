<template>
  <div id="chart-theme">
    <el-form @submit.native.prevent ref="form">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="Chart Theme" name="1">
          <el-form-item label="Mode">
            <el-radio-group
              @change="updateThemeMode"
              size="medium"
              v-model="theme.mode"
            >
              <el-radio-button label="light">
                <i class="el-icon-sunny"></i>
              </el-radio-button>
              <el-radio-button label="dark">
                <i class="el-icon-moon"></i>
              </el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item class="mt3" label="Chart Background Color">
            <extended-el-color-picker
              @change="updateChart"
              v-model="backgroundColor"
            ></extended-el-color-picker>
          </el-form-item>
          <el-form-item label="Chart Text Color">
            <extended-el-color-picker
              @change="updateChart"
              v-model="foregroundColor"
            ></extended-el-color-picker>
          </el-form-item>

          <h4>
            <el-radio @change="updateTheme" v-model="chkColors" label="theme"
              >Select a Predefined Theme</el-radio
            >
          </h4>

          <el-form-item class="full-width">
            <el-select
              :disabled="chkColors === 'colors'"
              @change="updateTheme"
              v-model="theme.palette"
              placeholder="Select Theme"
            >
              <el-option
                v-for="item in palettes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right;">
                  <div class="color-palettes">
                    <div
                      class="c-box"
                      :style="{ background: c }"
                      v-for="(c, index) in item.colors"
                      :key="index"
                    ></div>
                  </div>
                </span>
              </el-option>
            </el-select>
          </el-form-item>

          <h4>
            <el-radio v-model="chkColors" @change="updateColors" label="colors"
              >Use Custom Colors</el-radio
            >
          </h4>
          <el-form-item
            :class="{
              'custom-colors-picker': true,
              disabled: chkColors === 'theme',
            }"
          >
            <extended-el-color-picker
              v-for="(color, index) in colors"
              :key="index"
              @change="
                (val) => {
                  updateColors(val, index);
                }
              "
              :value="color"
            ></extended-el-color-picker>
            <el-button @click="appendColor" size="mini" class="ml2">
              <i class="el-icon-plus"></i>
            </el-button>
            <el-button
              @click="clearColors"
              size="mini"
              title="Delete All Colors"
            >
              <i class="el-icon-delete"></i>
            </el-button>
          </el-form-item>
        </el-collapse-item>
        <el-collapse-item title="DropShadow" name="2">
          <el-form-item label="Enable">
            <el-switch
              inactive-color="#24272b"
              @change="updateChart"
              v-model="dropShadow.enabled"
            ></el-switch>
          </el-form-item>

          <h4>Blur</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="dropShadow.blur"
              :max="30"
            ></el-slider>
          </div>

          <h4>Offset X</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="dropShadow.left"
              :min="-30"
              :max="30"
            ></el-slider>
          </div>

          <h4>Offset Y</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="dropShadow.top"
              :min="-30"
              :max="30"
            ></el-slider>
          </div>

          <el-form-item label="Shadow Color">
            <extended-el-color-picker
              @change="updateChart"
              v-model="dropShadow.color"
            ></extended-el-color-picker>
          </el-form-item>

          <h4>Shadow Opacity</h4>
          <div class="block">
            <el-slider
              @change="updateChart"
              v-model="dropShadow.opacity"
              :step="0.05"
              :min="0"
              :max="1"
            ></el-slider>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      activeNames: ["1", "2"],
      foregroundColor: "#333",
      backgroundColor: "",
      colors: [],
      theme: {
        mode: "light",
        palette: "palette4",
      },
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 2,
        left: 2,
        blur: 4,
        color: "#000",
        opacity: 0.35,
      },
      palettes: [
        {
          value: "palette1",
          label: "palette1",
          colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
        },
        {
          value: "palette2",
          label: "palette2",
          colors: ["#3f51b5", "#03a9f4", "#4caf50", "#f9ce1d", "#FF9800"],
        },
        {
          value: "palette3",
          label: "palette3",
          colors: ["#33b2df", "#546E7A", "#d4526e", "#13d8aa", "#A5978B"],
        },
        {
          value: "palette4",
          label: "palette4",
          colors: ["#4ecdc4", "#c7f464", "#81D4FA", "#fd6a6a", "#546E7A"],
        },
        {
          value: "palette5",
          label: "palette5",
          colors: ["#2b908f", "#f9a3a4", "#90ee7e", "#fa4443", "#69d2e7"],
        },
      ],
      chkColors: "theme",
    };
  },
  watch: {
    chartConfig: function(newProps) {
      if (!newProps) return;

      this.initProps(newProps);
    },
  },
  computed: {
    selectedPaletteIndex: {
      get() {
        return this.theme.palette.match(/\d+/)[0] - 1;
      },
      set(newIndex) {
        return newIndex;
      },
    },
  },
  mounted: function() {
    const chartConfig = this.getChartConfig().w.config;

    this.initProps(chartConfig);
  },
  methods: {
    initProps(newProp) {
      this.foregroundColor = newProp.chart.foreColor;
      this.backgroundColor = newProp.chart.background;

      this.colors =
        newProp.colors && newProp.colors.length
          ? newProp.colors
          : this.palettes[this.selectedPaletteIndex].colors;

      this.dropShadow = {
        enabled: newProp.chart.dropShadow.enabled,
        top: newProp.chart.dropShadow.top,
        left: newProp.chart.dropShadow.left,
        blur: newProp.chart.dropShadow.blur,
        color: newProp.chart.dropShadow.color,
        opacity: newProp.chart.dropShadow.opacity,
      };
      this.theme = {
        mode: newProp.theme.mode,
        palette: newProp.theme.palette,
      };

      if (newProp.colors) {
        this.chkColors = "colors";
      }
    },
    updateTheme() {
      const val = this.theme.palette;
      this.selectedPaletteIndex = val.match(/\d+/)[0] - 1;
      this.updateChart();
    },

    updateThemeMode() {
      let gridColor = "#e0e0e0";
      if (this.theme.mode === "dark") {
        this.backgroundColor = "#343E59";
        this.foregroundColor = "#fff";
        gridColor = "#6e7eaa";
      } else {
        this.backgroundColor = "#fff";
        this.foregroundColor = "#373D3F";
      }
      this.updateChartOptions({
        chart: {
          background: this.backgroundColor,
          foreColor: this.foregroundColor,
        },
        grid: {
          borderColor: gridColor,
        },
        theme: {
          palette: this.theme.palette,
          mode: this.theme.mode,
        },
      });
    },

    updateColors(color, index) {
      Vue.set(this.colors, index, color);
      this.updateChart();
    },
    appendColor() {
      this.colors.push("#f1f2f3");
      this.updateChart();
    },
    clearColors() {
      this.colors = [];
    },

    updateChart() {
      this.updateChartOptions({
        chart: {
          background: !this.backgroundColor
            ? "transparent"
            : this.backgroundColor,
          foreColor: this.foregroundColor,

          dropShadow: {
            enabled: this.dropShadow.enabled,
            top: this.dropShadow.top,
            left: this.dropShadow.left,
            blur: this.dropShadow.blur,
            color: this.dropShadow.color,
            opacity: this.dropShadow.opacity,
          },
        },
        colors: this.chkColors === "colors" ? this.colors : undefined,
        theme: {
          palette: this.theme.palette,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.color-palettes {
  .c-box {
    width: 28px;
    height: 28px;
    display: inline-block;
    margin: 3px 2px 2px;
    border-radius: 4px;
  }
}
</style>
